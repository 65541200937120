import React from "react"
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import "./toddler-track.css";
import Img from "gatsby-image";
import Button from "react-bootstrap/Button";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";

const ToddlerTrackPage = ({data}) => {

  return (
    <Layout className="site-content">
      <div className="toddler-track-container">
        <div>
          <h1 className={globalStyle.entryTitle}>Parenting Toddlers Virtual Village</h1>
        </div>

        <div>
          <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
        </div>

        <div className="col-md-12">
          <div>
            <div className="product-price">$180</div>
            <div className="form-container">
              <Form>
                <Form.Group as={Row} controlId="sessionOptionForm" className="select-option-form">
                  <Form.Label>Session Options</Form.Label>
                  <Form.Control as="select" placeholder="Choose an option" className="select-option" >
                    <option>Choose an option</option>
                    <option>West Coast: 6 Tuesdays Jan - Feb</option>
                    <option>East Coast: 6 Tuesdays Jan - Feb</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Row} controlId="quantityForm" className="add-to-cart-form">
                  <Form.Control
                    type="number"
                    placeholder={1}
                    min={0}
                    max={100} >
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit" className="add-to-cart-button">
                  Select
                </Button>
              </Form>
            </div>
          </div>
          <div className="about-product">
            <div className="category">
              <span>Category: <Link className="linkToPage" to="/parenting-village/">Parent Village</Link></span>
            </div>
           <h4>Learn how to Parent Toddlers Calmly, Confidently and Respectfully</h4>
            <p>If we’re being truly honest parenting toddlers is hard work! While we love them fiercely there are absolutely
              times we find ourselves stressed, overwhelmed and frustrated with our kids.  And they don’t come with a manual
              so how do you fix this!  The truth is there’s no one right way to parent. What’s important is to find the way
              that’s right for you and your family.  This parenting class teaches practical and effective tools for
              understanding and parenting your toddler using positive discipline solutions. Taking this class you’ll build a
              strong parenting foundation needed to encourage the development of key characteristics and life skills in your
              toddler.</p>
            <br />
            <p>We have East Cost and West Coast village groups. Both groups meet Tuesdays beginning January 7th from 8-9:15PM
              in their Local Time zone. Please select West or East coast when you register.</p>

          </div>

          <div>
            <Tabs id="controlled-tab-example" className="tabTitleContainer">
              <Tab eventKey="description" title="Description" className="tabTitle">
                <div className="description-content">
                  <h3>What’s Covered</h3>
                  <p>The information learned in this class will help you define your parenting, develop your parenting goals,
                    and help you get more intentional in your parenting.  All Virtual Village members get exclusive access to our
                    insider-only, bonus content with the BEST experts as vetted by Moms on Maternity. Learn fashion and clothing secrets,
                    tips for feeding your whole family (especially the kids), how to be more mindful as a parent and so much more.
                    A special gift for Mama will be delivered at the end of the six week program too.
                    Come join us! We can’t wait to meet you.</p>

                  <h3>How does it work?</h3>
                  <p>Each group has 6-8 new parents. Video conferencing is done using Zoom and all from the comfort from your
                    own home! Just click on the link we send you from your phone or your computer and you are instantly
                    connected with your group and our MOM Virtual Tribe Leaders.</p>

                  <p>Group will meet Thursdays from 8-9:15PM beginning January 16th.</p>

                  <h3>BONUS</h3>
                  <p>When you join our village, our MOM Educator, Ali Glinsky interviews and asks the hard questions you really
                    want to know from the experts in their field. The interviews include experts in Pediatric Nutrition,
                    Mindful Parenting, Personal Bran Style, a Sleep Consultant and much more! The interviews are all
                    accessible online so you can sit back and watch from the comfort of your home or office.
                    These interviews are sure to help you along in any part of your parenting journey!</p>
                </div>
              </Tab>
              <Tab eventKey="additional-information" title="Additional information" className="additional-information tabTitle">
                <div className="informations-content">
                  <p>Session Options</p>
                  <span> West Coast: 6 Tuesdays Jan – Feb, East Coast: 6 Tuesdays Jan – Feb</span>
                </div>
              </Tab>
            </Tabs>
          </div>

          <Row className="justify-content-center row relatedProducts">
            <h2>Related products</h2>
            <Col md={10}>
              <Row className="newBaby">
                <Col xs={12} md={6} sm={6} className="buttonContainer">
                  <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
                  <Link className={globalStyle.redirectLink + " redirectLink"} to="/product/new-baby-virtual-village-0-6-months-1/">
                    <p>
                        New Baby Virtual Village | 0-6 Months
                    </p>
                  </Link>
                </Col>
                <Col xs={12} md={6} sm={6} className="buttonContainer"></Col>
              </Row>
            </Col>
          </Row>
        </div>

      </div>
    </Layout>
  )
};

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/parenting-village\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          name
        }
      }
    }
  }
`;

export default ToddlerTrackPage
